import { InfoCell } from '@atoms';
import { ROUTES } from '@constants';
import { Table } from '@molecules';
import { Box, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { CompanyType } from '@services';
import { AdminActivityResponse, AdminActivityType } from '@services/types/activity';
import dayjs from 'dayjs';

export function ActionLog() {
  const columns: GridColDef<AdminActivityResponse>[] = [
    {
      field: 'user',
      headerName: 'User',
      width: 300,
      filterable: false,
      sortable: false,
      renderCell: ({ row }) => {
        const { adminName } = row;
        return <InfoCell name={adminName} avatarUrl={adminName || ''} />;
      },
    },
    {
      field: 'log',
      headerName: 'Log',
      filterable: false,
      sortable: false,
      flex: 1,
      renderCell: ({ row }) => {
        const { text, createdAt } = row;
        return (
          <Box>
            <Box fontSize={15}>{text}</Box>
            <Box color={'#A5A5A6'} fontSize={12}>
              {dayjs(createdAt).format('DD/MM/YYYY HH:mm')}
            </Box>
          </Box>
        );
      },
    },
  ];

  const openNewTab = (pathname: string) => {
    window.open(pathname, '_blank');
  };

  const onClickLog = (type: AdminActivityType, data: any) => {
    const { userId, modelId, companyId } = data;
    switch (type) {
      // Content Creator Activities
      case AdminActivityType.BlockContentCreatorAccount:
      case AdminActivityType.UpdateContentCreatorInfo:
        companyId &&
          modelId &&
          openNewTab(`/${ROUTES.ContentCreators}/${companyId}/${modelId}/${ROUTES.ModelDetails}`);
        break;
      case AdminActivityType.UploadLinksCC:
      case AdminActivityType.ExportLinksCC:
      case AdminActivityType.UpdateLinksSourceTypesCC:
      case AdminActivityType.UpdateLinksStatusCC:
      case AdminActivityType.DeleteLinksCC:
      case AdminActivityType.UpdateLinksWorkingModeStatusCC:
      case AdminActivityType.DeleteLinksWorkingModeStatusCC:
        companyId &&
          modelId &&
          openNewTab(
            `/${ROUTES.ContentCreators}/${companyId}/${modelId}/${ROUTES.LinksManagement}`,
          );
        break;
      case AdminActivityType.UpdateScanSettingsCC:
        companyId &&
          modelId &&
          openNewTab(`/${ROUTES.ContentCreators}/${companyId}/${modelId}/${ROUTES.ScanSettings}`);
        break;
      case AdminActivityType.UploadDocumentCC:
      case AdminActivityType.DeleteDocumentCC:
        companyId &&
          modelId &&
          openNewTab(`/${ROUTES.ContentCreators}/${companyId}/${modelId}/${ROUTES.UploadDocument}`);
        break;
      case AdminActivityType.UploadWhiteListsCC:
      case AdminActivityType.UpdateWhiteListCC:
      case AdminActivityType.DeleteWhiteListCC:
        companyId &&
          modelId &&
          openNewTab(`/${ROUTES.ContentCreators}/${companyId}/${modelId}/${ROUTES.Whitelist}`);
        break;

      // Business Account Activities
      case AdminActivityType.InviteBusinessAccount:
      case AdminActivityType.UpdateBusinessAccount:
      case AdminActivityType.RemoveCompanyAsset:
      case AdminActivityType.MoveCompanyAssets:
        companyId &&
          openNewTab(`/${ROUTES.BusinessAccounts}/${companyId}/${ROUTES.AccountDetails}`);
        break;
      case AdminActivityType.AddCompanyAsset:
      case AdminActivityType.UpdateCompanyAsset:
        companyId &&
          modelId &&
          openNewTab(
            `/${ROUTES.AssetAccount}/${CompanyType.Business}/${companyId}/${modelId}/${ROUTES.AccountDetails}`,
          );
        break;
      case AdminActivityType.UpdateCompanyWorkspace:
      case AdminActivityType.BlockCompanyWorkspace:
        companyId && openNewTab(`/${ROUTES.BusinessAccounts}/${companyId}/${ROUTES.Workspace}`);
        break;
      case AdminActivityType.UploadWhiteListsBA:
      case AdminActivityType.UpdateWhiteListBA:
      case AdminActivityType.DeleteWhiteListBA:
        companyId && openNewTab(`/${ROUTES.BusinessAccounts}/${companyId}/${ROUTES.Whitelist}`);
        break;
      case AdminActivityType.UploadLinksBA:
      case AdminActivityType.ExportLinksBA:
      case AdminActivityType.UpdateLinksSourceTypesBA:
      case AdminActivityType.UpdateLinksStatusBA:
      case AdminActivityType.DeleteLinksBA:
      case AdminActivityType.UpdateLinksWorkingModeStatusBA:
      case AdminActivityType.DeleteLinksWorkingModeStatusBA:
        modelId &&
          companyId &&
          openNewTab(
            `/${ROUTES.AssetAccount}/${CompanyType.Business}/${companyId}/${modelId}/${ROUTES.LinksManagement}`,
          );
        break;
      case AdminActivityType.UpdateScanSettingsBA:
        modelId &&
          companyId &&
          openNewTab(
            `/${ROUTES.AssetAccount}/${CompanyType.Business}/${companyId}/${modelId}/${ROUTES.ScanSettings}`,
          );
        break;
      case AdminActivityType.UploadDocumentBA:
      case AdminActivityType.DeleteDocumentBA:
        modelId &&
          companyId &&
          openNewTab(
            `/${ROUTES.AssetAccount}/${CompanyType.Business}/${companyId}/${modelId}/${ROUTES.UploadDocument}`,
          );
        break;

      // User Activities
      case AdminActivityType.InviteUser:
      case AdminActivityType.AsignUsers:
      case AdminActivityType.BlockUser:
      case AdminActivityType.UnblockUser:
      case AdminActivityType.UpdateUser:
      case AdminActivityType.ChangeAccountPassword:
      case AdminActivityType.UpdateAccountInformation:
        userId && openNewTab(`/${ROUTES.Users}/${userId}`);
        break;

      // Esign Activities
      case AdminActivityType.ChangeActiveContract:
      case AdminActivityType.EditContract:
        openNewTab(`/${ROUTES.ESign}?screen=Document+Templates`);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Box mb="16px" display="flex" justifyContent="space-between">
        <Typography variant="h4">Action Logs</Typography>
      </Box>

      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          padding: '32px 24px',
          marginTop: '12px',
          border: '1px solid #e0e0e0',
          borderRadius: '12px',
        }}
      >
        <Table<AdminActivityResponse>
          queryName="/admin/admin-activities"
          columns={columns}
          apiRoute="/admin/admin-activities"
          refetchQueryName="/admin/admin-activities"
          searchPlaceholder="Search"
          onCellClick={(params) => {
            const { row, field } = params;
            if (field === 'user') {
              openNewTab(`/${ROUTES.Users}/${row.adminId}`);
            }
            if (field === 'log') {
              onClickLog(row.type, row.payload);
            }
          }}
        />
      </Box>
    </>
  );
}

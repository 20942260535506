/* eslint-disable no-useless-escape */
import { ReactElement, ReactNode } from 'react';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import PaidIcon from '@mui/icons-material/Paid';
import DeleteIcon from '@mui/icons-material/Delete';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import BlockIcon from '@mui/icons-material/Block';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import HelpIcon from '@mui/icons-material/Help';
import MoneyOffCsredIcon from '@mui/icons-material/MoneyOffCsred';

import {
  ActiveStatus,
  AssignStatusType,
  AssetsType,
  CompanyType,
  CreatorPaymentStatus,
  LinkSourceType,
  LinkStatuses,
  MemberRole,
  MemberStatus,
  OptionType,
  Plans,
  ScanSourcesType,
  SourceType,
  AssignAssetType,
  NotificationRedirectType,
  LinkAtSourceInformationAiMatches,
  ScanSettingsAiFeaturesType,
} from '@services';
import { CopyrightContentStatus } from '@services/types/copyright';

export const BASE_URL = process.env.REACT_APP_BASE_URL || 'https://admin.dev.ceartas.io';

export enum USER_ROLES {
  Admin = 'admin',
  Manager = 'manager',
  Client = 'client',
}

export const PASSWORD_VALIDATION_REGEXPS = {
  cases: /([A-Z].*[a-z]|[a-z].*[A-Z])/,
  number: /\d/,
  specialSymbol: /[@$!%*#?&_\-~^()+=|}{\[\]\\”":;’'`><,./]/,
};

export const DATE_FORMAT = 'DD.MM.YYYY';

export const USER_STATUS_ICONS: Record<string, ReactNode> = {
  [ActiveStatus.Active]: <CheckCircleIcon fontSize="small" />,
  [ActiveStatus.EmailSent]: <MarkEmailReadIcon fontSize="small" />,
  [ActiveStatus.Disabled]: <BlockIcon fontSize="small" />,
  [ActiveStatus.Free]: <MoneyOffIcon fontSize="small" />,
  [ActiveStatus.Pending]: <AccessTimeFilledIcon fontSize="small" />,
  [ActiveStatus.Overdue]: <AccessTimeFilledIcon fontSize="small" />,
};

export const USER_STATUS_TEXT: Record<string, string> = {
  [ActiveStatus.Active]: 'Active',
  [ActiveStatus.EmailSent]: 'Email sent',
  [ActiveStatus.Disabled]: 'Disabled',
  [ActiveStatus.Free]: 'Free',
  [ActiveStatus.Pending]: 'Pending',
  [ActiveStatus.Overdue]: 'Overdue',
};

interface LinkStatusProp {
  icon?: ReactElement;
  label: string;
  color: 'error' | 'black' | 'warning' | 'success' | 'disabled';
}

export const LINK_STATUS_CONTENT: Record<string, LinkStatusProp> = {
  [LinkStatuses.Raw]: {
    icon: <ErrorOutlineIcon className="icon" fontSize="small" />,
    label: 'Raw',
    color: 'disabled',
  },
  [LinkStatuses.Submitted]: {
    icon: <VerifiedUserIcon className="icon" fontSize="small" />,
    label: 'Submitted',
    color: 'success',
  },
  [LinkStatuses.Infringing]: {
    icon: <AccessTimeFilledIcon className="icon" fontSize="small" />,
    label: 'Infringing',
    color: 'warning',
  },
  [LinkStatuses.FalsePositive]: {
    icon: <BlockIcon className="icon" fontSize="small" />,
    label: 'False positive',
    color: 'black',
  },
  [LinkStatuses.Invalid]: {
    icon: <HelpIcon className="icon" fontSize="small" />,
    label: 'Invalid',
    color: 'error',
  },
};

export const PAYMENT_STATUS: Record<string, Omit<LinkStatusProp, 'color'>> = {
  [CreatorPaymentStatus.ActivePaid]: {
    icon: <PaidIcon fontSize="small" />,
    label: 'Active',
  },
  [CreatorPaymentStatus.ActiveFree]: {
    icon: <MoneyOffIcon fontSize="small" />,
    label: 'Active',
  },
  [CreatorPaymentStatus.Trial]: {
    icon: <MoneyOffIcon fontSize="small" />,
    label: 'Trialing',
  },
  [CreatorPaymentStatus.EmailSent]: {
    icon: <MarkEmailReadIcon fontSize="small" />,
    label: 'Email Sent',
  },
  [CreatorPaymentStatus.Overdue]: {
    icon: <AccessTimeFilledIcon fontSize="small" />,
    label: 'Overdue',
  },
  [CreatorPaymentStatus.Deleted]: {
    icon: <DeleteIcon fontSize="small" />,
    label: 'Deleted',
  },
  [CreatorPaymentStatus.Canceled]: {
    icon: <MoneyOffCsredIcon fontSize="small" />,
    label: 'Canceled',
  },
  [CreatorPaymentStatus.Paused]: {
    icon: <PauseCircleFilledIcon fontSize="small" />,
    label: 'Paused',
  },
};
export const PAYMENT_FILTER_STATUS: Record<string, Omit<LinkStatusProp, 'color'>> = {
  [CreatorPaymentStatus.ActivePaid]: {
    icon: <PaidIcon fontSize="small" />,
    label: 'Active',
  },
  [CreatorPaymentStatus.ActiveFree]: {
    icon: <MoneyOffIcon fontSize="small" />,
    label: 'Active',
  },
  [CreatorPaymentStatus.EmailSent]: {
    icon: <MarkEmailReadIcon fontSize="small" />,
    label: 'Email Sent',
  },
  [CreatorPaymentStatus.Overdue]: {
    icon: <AccessTimeFilledIcon fontSize="small" />,
    label: 'Overdue',
  },
  [CreatorPaymentStatus.Deleted]: {
    icon: <DeleteIcon fontSize="small" />,
    label: 'Deleted',
  },
  [CreatorPaymentStatus.Canceled]: {
    icon: <MoneyOffCsredIcon fontSize="small" />,
    label: 'Canceled',
  },
  [CreatorPaymentStatus.Paused]: {
    icon: <PauseCircleFilledIcon fontSize="small" />,
    label: 'Paused',
  },
  [CreatorPaymentStatus.Trial]: {
    icon: <MoneyOffIcon fontSize="small" />,
    label: 'Trialing',
  },
};

export const SOURCE_STATUS_NAME: Record<SourceType | LinkSourceType, string> = {
  [SourceType.GoogleImages]: 'Google Images',
  [SourceType.GoogleWeb]: 'Google Web',
  [SourceType.AtSource]: 'At source',
  [LinkSourceType.All]: 'All',
  [LinkSourceType.GoogleImages]: 'Google Images',
  [LinkSourceType.GoogleWeb]: 'Google Web',
  [LinkSourceType.AtSource]: 'At source',
  [LinkSourceType.SubmittedManually]: 'Manually Submitted',
};

export const SUBMIT_BUTTON_ID = 'submit-button';
export const BACK_BUTTON_ID = 'back-button';
export const HAS_SOCIAL_ERROR = 'has-social-error';

export const DEFAULT_KEYWORDS =
  'nude,\nleaked,\nsite\nrip,\nonlyfans\nleaked,\ncamwhore,\nporn,\nsite:nudesjar.com,\nsite:fapello.com,\nsite:cambro.tv,\nsite:nsfw247.to,\nsite:onlyleaks.vip,\nsite:hqcollect.net,\nsite:daftsex-hd.com,\nsite:fapachi.com,\nsite:vip-pussy.com';

export const PLANS_LABEL: Record<Plans, string> = {
  [Plans.Vip]: 'VIP',
  [Plans.Star]: 'Star',
  [Plans.Free]: 'Free',
  [Plans.Elite]: 'Elite',
  [Plans.Business]: 'Business',
  [Plans.Agency]: 'Agency',
  [Plans.Enterprise]: 'Enterprise',
  [Plans.Platinum]: 'Platinum',
};

export const CREATOR_PLANS_OPTIONS: OptionType[] = [
  { value: Plans.Free, label: PLANS_LABEL[Plans.Free] },
  { value: Plans.Star, label: PLANS_LABEL[Plans.Star] },
  { value: Plans.Elite, label: PLANS_LABEL[Plans.Elite] },
  { value: Plans.Vip, label: PLANS_LABEL[Plans.Vip] },
  { value: Plans.Platinum, label: PLANS_LABEL[Plans.Platinum] },
];

export const FILTER_CREATOR_PLANS_OPTIONS: OptionType[] = CREATOR_PLANS_OPTIONS;

export const BUSINESS_PLANS_OPTIONS: OptionType[] = [
  { value: Plans.Free, label: PLANS_LABEL[Plans.Free] },
  { value: Plans.Agency, label: PLANS_LABEL[Plans.Agency] },
  { value: Plans.Business, label: PLANS_LABEL[Plans.Business] },
  { value: Plans.Enterprise, label: PLANS_LABEL[Plans.Enterprise] },
];

export const FILTER_BUSINESS_PLANS_OPTIONS: OptionType[] = BUSINESS_PLANS_OPTIONS;

export const USER_ROLES_OPTIONS: OptionType[] = [
  { value: USER_ROLES.Manager, label: 'Manager' },
  { value: USER_ROLES.Admin, label: 'Administrator' },
];

export const COMPANY_TYPE_LABEL: Record<CompanyType, string> = {
  [CompanyType.Business]: 'Business',
  [CompanyType.ContentCreator]: 'Content Creator',
};

export const ASSIGN_ASSET_TYPE_LABEL: Record<AssignAssetType, string> = {
  [AssignAssetType.Business]: 'Business',
  [AssignAssetType.ContentCreator]: 'Content Creator',
  [AssignAssetType.Model]: 'Asset',
};

export const MEMBER_ROLE_LABEL: Record<MemberRole, string> = {
  [MemberRole.CompanyAdmin]: 'Admin',
  [MemberRole.CompanyManager]: 'Manager',
  [MemberRole.CompanyInfluencer]: 'Content Creator',
};

export const MEMBER_STATUS_ICONS: Record<string, ReactNode> = {
  [MemberStatus.Active]: <CheckCircleIcon fontSize="small" />,
  [MemberStatus.EmailSent]: <MarkEmailReadIcon fontSize="small" />,
  [MemberStatus.Blocked]: <BlockIcon fontSize="small" />,
};

export const MEMBER_STATUS_TEXT: Record<string, string> = {
  [MemberStatus.Active]: 'Active',
  [MemberStatus.EmailSent]: 'Pending',
  [MemberStatus.Blocked]: 'Blocked',
};

export const MEMBER_STATUS_OPTIONS: OptionType[] = [
  {
    value: MemberStatus.Active,
    label: MEMBER_STATUS_TEXT[MemberStatus.Active],
    icon: MEMBER_STATUS_ICONS[MemberStatus.Active],
  },
  {
    value: MemberStatus.EmailSent,
    label: MEMBER_STATUS_TEXT[MemberStatus.EmailSent],
    icon: MEMBER_STATUS_ICONS[MemberStatus.EmailSent],
  },
  {
    value: MemberStatus.Blocked,
    label: MEMBER_STATUS_TEXT[MemberStatus.Blocked],
    icon: MEMBER_STATUS_ICONS[MemberStatus.Blocked],
  },
];

export const MEMBER_ROLE_OPTIONS: OptionType[] = [
  { value: MemberRole.CompanyAdmin, label: MEMBER_ROLE_LABEL[MemberRole.CompanyAdmin] },
  { value: MemberRole.CompanyManager, label: MEMBER_ROLE_LABEL[MemberRole.CompanyManager] },
  { value: MemberRole.CompanyInfluencer, label: MEMBER_ROLE_LABEL[MemberRole.CompanyInfluencer] },
];

export const ASSIGN_STATUS_OPTIONS: OptionType[] = [
  { value: AssignStatusType.Assign, label: 'Assign' },
  { value: AssignStatusType.Unassign, label: 'Unassign' },
];

export const COMPANY_TYPE_OPTIONS: OptionType[] = [
  { value: CompanyType.ContentCreator, label: COMPANY_TYPE_LABEL[CompanyType.ContentCreator] },
  { value: CompanyType.Business, label: COMPANY_TYPE_LABEL[CompanyType.Business] },
];

export const SCAN_SOURCES_LABEL: Record<ScanSourcesType, string> = {
  [ScanSourcesType.GoogleImages]: 'Google images',
  [ScanSourcesType.GoogleWeb]: 'Google web',
  [ScanSourcesType.AtSource]: 'At source',
  [ScanSourcesType.ReverseImageSearch]: 'Reverse image search',
};

export const LINK_AT_SOURCE_INFORMATION_AI_MATCHES: Record<
  LinkAtSourceInformationAiMatches,
  string
> = {
  [LinkAtSourceInformationAiMatches.PeopleFound]: 'People found',
  [LinkAtSourceInformationAiMatches.Nsfw]: 'NSFW',
  [LinkAtSourceInformationAiMatches.PersonMatched]: 'Person matched',
  [LinkAtSourceInformationAiMatches.ObjectOfInterest]: 'Object of interest',
  [LinkAtSourceInformationAiMatches.VideoStreamFound]: 'Video stream found',
  [LinkAtSourceInformationAiMatches.InfringingMatch]: 'Infringing match',
};

export const SCAN_SOURCES_OPTIONS: OptionType[] = [
  { value: ScanSourcesType.GoogleImages, label: SCAN_SOURCES_LABEL[ScanSourcesType.GoogleImages] },
  { value: ScanSourcesType.GoogleWeb, label: SCAN_SOURCES_LABEL[ScanSourcesType.GoogleWeb] },
  { value: ScanSourcesType.AtSource, label: SCAN_SOURCES_LABEL[ScanSourcesType.AtSource] },
  {
    value: ScanSourcesType.ReverseImageSearch,
    label: SCAN_SOURCES_LABEL[ScanSourcesType.ReverseImageSearch],
  },
];

export const SCAN_AI_FEATURES_OPTIONS: Array<{
  value: ScanSettingsAiFeaturesType;
  label: string;
}> = [
  { value: ScanSettingsAiFeaturesType.FaceMatch, label: 'Face Match' },
  { value: ScanSettingsAiFeaturesType.Watermark, label: 'Watermark' },
  { value: ScanSettingsAiFeaturesType.ObjectDetect, label: 'Object detect' },
  { value: ScanSettingsAiFeaturesType.OcrDetect, label: 'OCR detection' },
  { value: ScanSettingsAiFeaturesType.People, label: 'People' },
  { value: ScanSettingsAiFeaturesType.Nsfw, label: 'NSFW' },
];

export const COMPANY_ASSETS_LABEL: Record<AssetsType, string> = {
  [AssetsType.Books]: 'Books',
  [AssetsType.Games]: 'Games',
  [AssetsType.Movies]: 'Movies',
  [AssetsType.Software]: 'Software',
  [AssetsType.TV]: 'TV',
  [AssetsType.Music]: 'Music',
  [AssetsType.Models]: 'Assets',
  [AssetsType.NFT]: 'NFT’s',
  [AssetsType.Talents]: 'Talents',
  [AssetsType.TVPLE]: 'TV&PLE',
  [AssetsType.Merchandise]: 'Merchandise',
};

export enum NOTIFICATION_RESPONSE_TYPE {
  NewClient = 'newClient',
  OverdueAccount = 'overdueAccount',
  ManuallySubmittedLinks = 'manuallySubmittedLinks',
  ClientCancelSubscription = 'clientCancelSubscription',
  ClientBecomePaid = 'clientBecomePaid',
  CompanyAssigned = 'companyAssigned',
  ModelAssigned = 'modelAssigned',
  ClientUpgradedPlan = 'clientUpgradedPlan',
  ClientDowngradedPlan = 'clientDowngradedPlan',
}

export const NOTIFICATION_REDIRECT_TYPE: NotificationRedirectType = {
  ChangePlans: [
    NOTIFICATION_RESPONSE_TYPE.ClientDowngradedPlan,
    NOTIFICATION_RESPONSE_TYPE.ClientUpgradedPlan,
    NOTIFICATION_RESPONSE_TYPE.ClientBecomePaid,
  ],
  NewSignUps: [NOTIFICATION_RESPONSE_TYPE.NewClient],
  SubmittedLinks: [NOTIFICATION_RESPONSE_TYPE.ManuallySubmittedLinks],
  Cancellations: [NOTIFICATION_RESPONSE_TYPE.ClientCancelSubscription],
};

export const COPYRIGHT_STATUS_CONTENT: Record<string, LinkStatusProp> = {
  [CopyrightContentStatus.Pending]: {
    icon: <AccessTimeFilledIcon className="icon" fontSize="small" />,
    label: 'Pending',
    color: 'warning',
  },
  [CopyrightContentStatus.Completed]: {
    icon: <VerifiedUserIcon className="icon" fontSize="small" />,
    label: 'Completed',
    color: 'success',
  },
};

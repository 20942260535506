/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CompanyType,
  CreatorPaymentStatus,
  NotificationRedirect,
  PaymentStatus,
  Plans,
  Queries,
  successMessage,
} from '@services';
import { Auth } from 'aws-amplify';
import dayjs from 'dayjs';
import { BACK_BUTTON_ID, NOTIFICATION_REDIRECT_TYPE, ROUTES, SUBMIT_BUTTON_ID } from '@constants';
import { GridCellParams } from '@mui/x-data-grid';
import { FieldValues } from 'react-hook-form';

export const getToken = async () => {
  const authenticatedUser = await Auth.currentAuthenticatedUser();
  const userToken = authenticatedUser?.signInUserSession?.accessToken?.jwtToken;
  return userToken;
};

export const queryFormatted = (query: Record<string, any>): string => {
  if (!query) return '';

  return Object.entries(query).reduce(
    (result, [key, value], i) =>
      value ? `${result}${i === 0 ? '' : '&'}${key}=${encodeURIComponent(value)}` : result,
    '?',
  );
};

export const capitalize = (str: string): string => {
  if (!str) return '';

  return str.toLowerCase().charAt(0).toUpperCase() + str.toLowerCase().slice(1);
};

export const getLastModifiedColor = (lastModified: string): 'success' | 'warning' | 'error' => {
  const nowDate = dayjs();
  const lastModifiedDate = dayjs(lastModified);
  const diff = nowDate.diff(lastModifiedDate, 'day');

  if (diff < 7) return 'success';
  if (diff >= 7 && diff <= 14) return 'warning';
  return 'error';
};

export const formatQueries = (queries: Queries) => ({
  ...queries,
  page: String(queries.page),
  sortDirection: queries.sortDirection?.toUpperCase(),
});

export const formatSortDirection = (queries: { [x: string]: unknown }) =>
  // eslint-disable-next-line no-prototype-builtins
  queries.hasOwnProperty('sortDirection')
    ? { ...queries, sortDirection: (queries.sortDirection as string).toUpperCase() }
    : queries;

export const getStatus = (
  status: PaymentStatus,
  plan: Plans,
  isRemoved?: boolean,
  isFreeTrial?: boolean,
) => {
  if (isRemoved) {
    return CreatorPaymentStatus.Deleted;
  }
  switch (status) {
    case PaymentStatus.Active:
      if (plan === Plans.Free) return CreatorPaymentStatus.ActiveFree;
      return isFreeTrial ? CreatorPaymentStatus.Trial : CreatorPaymentStatus.ActivePaid;
    case PaymentStatus.Canceled:
      return CreatorPaymentStatus.Canceled;
    default:
      return status as unknown as CreatorPaymentStatus;
  }
};

export const onSubmitButtonClick = () => document?.getElementById(SUBMIT_BUTTON_ID)?.click();
export const onBackButtonClick = () => document?.getElementById(BACK_BUTTON_ID)?.click();

export const getParams = (searchParams: URLSearchParams) =>
  [...searchParams].reduce((acc, [key, value]) => {
    try {
      return { ...acc, [key]: JSON.parse(value) };
    } catch {
      return { ...acc, [key]: value };
    }
  }, {});

export const removedCellClassName = ({
  row: { isRemoved },
}: GridCellParams<{ isRemoved?: boolean }>) => (isRemoved ? 'disabled-row' : '');

export const getSelectedAccount = <T extends { id: string }>(
  items: T[],
  selectedItemsId: string[],
) => {
  const selectedItems = items.filter((item) =>
    selectedItemsId.includes((item as { id: string })?.id),
  );
  return selectedItems?.[0]?.id || '';
};

export const getUniqValues = <T extends FieldValues>(defaultValues: T, submitValues: T): T =>
  Object.keys(defaultValues).reduce((acc, key) => {
    if (defaultValues[key] !== submitValues[key]) {
      return { ...acc, [key]: submitValues[key] };
    }
    return acc;
  }, {}) as T;

export const copyToClipboard = (text: string, message?: string) => {
  navigator.clipboard.writeText(text);
  successMessage(message || 'Link has been copied!');
};

export const uncapitalizeFirstLetter = (string: string) =>
  string.charAt(0).toLowerCase() + string.slice(1);

export const getNotificationRedirectUrl = (value: NotificationRedirect): string => {
  const redirectType = value.type;
  const userType = value.payload?.type;
  const assetId = value.payload?.assetId;
  const companyId = value.payload?.companyId;
  if (!redirectType) return '';
  if (
    NOTIFICATION_REDIRECT_TYPE.ChangePlans.includes(redirectType) ||
    NOTIFICATION_REDIRECT_TYPE.NewSignUps.includes(redirectType) ||
    NOTIFICATION_REDIRECT_TYPE.Cancellations.includes(redirectType)
  ) {
    switch (userType) {
      case CompanyType.ContentCreator:
        return `/${ROUTES.ContentCreators}/${companyId}/${assetId}/${ROUTES.ModelDetails}`;
      case CompanyType.Business:
        return `/${ROUTES.BusinessAccounts}/${companyId}/${ROUTES.AccountDetails}`;
      default:
        return '';
    }
  } else if (NOTIFICATION_REDIRECT_TYPE.SubmittedLinks.includes(redirectType)) {
    switch (userType) {
      case CompanyType.ContentCreator:
        return `/${ROUTES.ContentCreators}/${companyId}/${assetId}/${ROUTES.LinksManagement}`;
      case CompanyType.Business:
        return `/${ROUTES.AssetAccount}/${CompanyType.Business}/${companyId}/${assetId}/${ROUTES.LinksManagement}`;
      default:
        return '';
    }
  }
  return '';
};

/* eslint-disable @typescript-eslint/no-empty-interface */

export interface Config {
  name: string;
  type: FieldType | null;
  mode: FieldMode;
}

export interface PayloadUpdateTemplates {
  name: string;
  description: string;
  config: Config[];
  active: boolean;
}

export interface ContractTemplate {
  id: string;
  name: string;
  description: string | null;
  active: boolean;
  docuSealId: number;
  docuSealData: DocuSealTemplate;
  config: Config[] | null;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
}

export interface DocuSealTemplate {
  id: number;
  name: string;
  slug: string;
  author: Author;
  fields: Field[];
  schema: Schema[];
  source: string;
  author_id: number;
  documents: Document[];
  folder_id: number;
  account_id: number;
  created_at: Date;
  submitters: Submitter[];
  updated_at: Date;
  archived_at: null;
  external_id: null;
  folder_name: string;
  preferences: DocuSealDataPreferences;
  application_key: null;
}

export interface Author {
  id: number;
  email: string;
  last_name: string;
  first_name: string;
}

export interface Document {
  id: number;
  url: string;
  uuid: string;
  filename: string;
  preview_image_url: string;
}

export interface Field {
  name: string;
  type: Type;
  uuid: string;
  areas: Area[];
  required: boolean;
  preferences: FieldPreferences;
  submitter_uuid: string;
}

export interface Area {
  h: number;
  w: number;
  x: number;
  y: number;
  page: number;
  attachment_uuid: string;
}

export interface FieldPreferences {
  format?: string;
}

export enum Type {
  Date = 'date',
  Signature = 'signature',
  Text = 'text',
  Initials = 'initials',
  Number = 'number',
  Image = 'image',
  Checkbox = 'checkbox',
  Multiple = 'multiple',
  File = 'file',
  Radio = 'radio',
  Select = 'select',
  Cells = 'cells',
  Stamp = 'stamp',
  Phone = 'phone',
}

export enum FieldType {
  ModelUsername = 'model_username',
  ModelFirstName = 'model_first_name',
  ModelLastName = 'model_last_name',
  ModelSocialMediaLinks = 'model_social_media_links',

  DateToday = 'date_today',
}

export enum FieldMode {
  Auto = 'auto',
  Manual = 'manual',
}

export const dataFieldType = [
  {
    type: Type.Text,
    fieldType: [
      FieldType.ModelUsername,
      FieldType.ModelFirstName,
      FieldType.ModelLastName,
      FieldType.ModelSocialMediaLinks,
    ],
  },
  {
    type: Type.Date,
    fieldType: [FieldType.DateToday],
  },
];

export interface DocuSealDataPreferences {}

export interface Schema {
  name: string;
  attachment_uuid: string;
}

export interface Submitter {
  name: string;
  uuid: string;
}

/*  */

export interface ResSubmissions {
  totalCount: number;
  items: ContractSubmission[];
}

export interface ContractSubmission {
  id: string;
  url: null;
  templateId: string;
  modelId: string;
  status: number;
  docuSealId: number;
  docuSealData: DocuSealSubmission;
  template: ContractTemplate;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: null;
  model: Model;
}

export interface DocuSealSubmission {
  id: number;
  slug: string;
  source: string;
  status: string;
  template: DocuSealTemplate;
  documents: Document2[];
  created_at: Date;
  submitters: DocuSealSubmitter[];
  updated_at: Date;
  archived_at: null;
  completed_at: Date;
  audit_log_url: string;
  created_by_user: CreatedByUser;
  submitters_order: string;
  submission_events: DocuSealSubmissionEvent[];
}

export interface CreatedByUser {
  id: number;
  email: string;
  last_name: string;
  first_name: string;
}

export interface Document2 {
  url: string;
  name: string;
}

export interface DocuSealSubmissionEvent {
  id: number;
  event_type: string;
  submitter_id: number;
  event_timestamp: Date;
}

export interface DocuSealSubmitter {
  id: number;
  name: string;
  role: string;
  slug: string;
  uuid: string;
  email: string;
  phone: null;
  status: string;
  values: Value[];
  sent_at: Date | null;
  metadata: Overrides;
  documents: Document[];
  opened_at: Date;
  created_at: Date;
  updated_at: Date;
  external_id: null;
  preferences: Preferences;
  completed_at: Date;
  submission_id: number;
  application_key: null;
}

export interface Overrides {}

export interface Preferences {
  send_sms: boolean;
  send_email: boolean;
}

export interface Value {
  field: string;
  value: string;
}

export interface Model {
  id: string;
  title: null;
  username: string;
  firstName: null;
  lastName: null;
  transparencyReportLink: null;
  overrideGoogleWeb: null;
  overrideGoogleImages: null;
  overrideAtSource: null;
  overrides: Overrides;
  properties: null;
  lastSubmittedAt: null;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: null;
  companyId: string;
  photoUrl: null;
  managerId: null;
  plan: null;
  lastUpdatePlanAt: null;
}

import { FC, LegacyRef, Dispatch, useEffect, useMemo } from 'react';
import pick from 'object.pick';
import { useMutation } from 'react-query';
import { FieldValues } from 'react-hook-form';
import { useProfile } from '@hooks/useProfile';
import { User, successMessage } from '@services';
import { accountValidation } from '@constants';
import { useFormWithPhoto } from '@hooks';
import { Axios, queryClient } from '@helpers';

import { Box } from '@mui/material';
import { TextField } from '@atoms';
import { UserAvatar } from '@molecules';

interface AccountSettingsFormProps {
  submitButtonRef: LegacyRef<HTMLButtonElement>;
  setSaveButtonDisabled: Dispatch<boolean>;
  setIsEditing: Dispatch<boolean>;
}

export const AccountSettingsForm: FC<AccountSettingsFormProps> = ({
  setSaveButtonDisabled,
  submitButtonRef,
  setIsEditing,
}) => {
  const { profile } = useProfile();
  const { mutate: updateProfile, isLoading } = useMutation<unknown, unknown, User>(
    ({ firstName, lastName }) => Axios.put('/user/profile', { firstName, lastName }),
    {
      onSuccess: () => {
        successMessage('Yor profile has been updated!');
        queryClient.refetchQueries('profile');
        setIsEditing(false);
      },
    },
  );
  const { mutate: updateAvatar } = useMutation<
    { url: string; data: User },
    unknown,
    { file: File; data: User }
  >(
    ({ file: newFile, data }) => {
      const formData = new FormData();
      formData.append('file', newFile);
      return Axios.post('/user/avatar', formData).then((res) => ({
        url: res.data.url,
        data,
      }));
    },
    {
      onSuccess: ({ url, data }) => {
        successMessage('Yor photo has been updated!');
        queryClient.setQueryData('profile', {
          ...profile,
          ...data,
          avatarUrl: `${url}?${new Date().valueOf()}`,
        });
        setIsEditing(false);
      },
    },
  );

  const onSubmit = async (data: FieldValues, file: File | null) => {
    if (isDirtyValues) {
      updateProfile(data as User);
    }
    if (file) {
      updateAvatar({ file, data: data as User });
    }
  };

  const defaultValues = useMemo(() => pick(profile, ['firstName', 'lastName']), [profile]);

  const { previewImg, isDirtyValues, isDirty, handleSubmit, control, onUpload } = useFormWithPhoto({
    schema: accountValidation,
    defaultValues,
    defaultImage: profile?.avatarUrl || '',
    onSubmit,
  });

  useEffect(() => {
    setSaveButtonDisabled(!isDirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  return (
    <Box component="form" mt="18px" onSubmit={handleSubmit}>
      <UserAvatar
        fullName={`${profile?.firstName} ${profile?.lastName}`}
        avatarUrl={previewImg || ''}
        role={profile?.role || ''}
        size={60}
        onLoad={onUpload}
        isEditing
      />
      <Box mt="32px" display="flex" width="664px" gap="24px">
        <TextField label="First name" placeholder="First name" control={control} name="firstName" />
        <TextField label="Last name" placeholder="Last name" control={control} name="lastName" />
      </Box>
      <button disabled={isLoading} style={{ opacity: 0 }} type="submit" ref={submitButtonRef} />
    </Box>
  );
};

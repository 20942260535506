import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Typography, Box, Avatar } from '@mui/material';
import { ManagerType } from '@services';

export const renderManagerCell =
  (withoutPhoto?: boolean) =>
  ({ value }: GridRenderCellParams) => {
    const manager = value as ManagerType | null;

    if (!manager) {
      return (
        <Typography color="text.disabled" variant="body2">
          No manager
        </Typography>
      );
    }

    const { firstName, lastName, avatarUrl } = manager;
    const fullName = `${firstName} ${lastName}`;

    return (
      <Box display="flex" alignItems="center">
        {!withoutPhoto && (
          <Avatar
            alt={fullName}
            sx={{ width: 32, height: 32, marginRight: '16px' }}
            src={avatarUrl}
          >
            {fullName[0].toUpperCase()}
          </Avatar>
        )}
        <Typography variant="body2">{fullName}</Typography>
      </Box>
    );
  };

import { useState, useMemo, useEffect } from 'react';
import { URLSearchParamsInit, useSearchParams } from 'react-router-dom';
import { FiltersQuery, Queries } from '@services';
import { getParams } from '@helpers';
import { useFilters } from './useFilters';

const DEFAULT_QUERIES: Queries = {
  page: 0,
  size: 10,
  sortBy: '',
  sortDirection: null,
  filterText: '',
};

type Keys = keyof Queries;

const getUniqQueries = (defaultQueries: Queries, queries: Queries) =>
  Object.keys(defaultQueries).reduce((acc, key) => {
    if (defaultQueries[key as Keys] === queries[key as Keys]) {
      return acc;
    }
    return { ...acc, [key]: queries[key as Keys] };
  }, {});

const getFilters = (
  defaultQueries: Queries,
  params: { [k: string]: string },
): [string[], FiltersQuery] => {
  const queryKeys = Object.keys(defaultQueries);
  let selectedFilters = Object.keys(params).filter((key) => !queryKeys.includes(key));
  const filters = selectedFilters.reduce((acc, key) => ({ ...acc, [key]: params[key] }), {});
  if (selectedFilters.includes('updatedStartDate') || selectedFilters.includes('updatedEndDate')) {
    selectedFilters = [
      ...selectedFilters.filter((type) => type !== 'updatedStartDate' && type !== 'updatedEndDate'),
      'lastModified',
    ];
  }
  return [selectedFilters, filters];
};

export const useTableQueries = (preSelectedFilters?: { [key: string]: string | undefined }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const params = getParams(searchParams);
  const [defaultSelectedFilters, defaultFilters] = getFilters(DEFAULT_QUERIES, params);
  const [selectedFilters, setSelectedFilters] = useState<string[]>(defaultSelectedFilters);
  const { filterChange, resetFilter, filters, setFilters } = useFilters(defaultFilters);

  const [queries, setQueries] = useState<Queries>({ ...DEFAULT_QUERIES, ...params });
  const uniqQueries = useMemo(() => getUniqQueries(DEFAULT_QUERIES, queries), [queries]);

  const clearFilters = useMemo(
    () =>
      Object.entries(filters).reduce(
        (acc, [key, value]) => (value ? { ...acc, [key]: value } : acc),
        {},
      ),
    [filters],
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setFilters((prev) => ({ ...prev, ...preSelectedFilters }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preSelectedFilters]);

  useEffect(() => {
    setSearchParams({ ...uniqQueries, ...clearFilters } as URLSearchParamsInit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uniqQueries, clearFilters]);

  return {
    queries,
    setQueries,
    filterChange,
    resetFilter,
    filters,
    setFilters,
    selectedFilters,
    setSelectedFilters,
  };
};

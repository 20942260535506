import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { Axios, onSubmitButtonClick } from '@helpers';
import { ROUTES } from '@constants';
import { MemberStatus, MemberType, successMessage } from '@services';
import { useConfirmationModal } from '@hooks';
import SaveIcon from '@mui/icons-material/Save';
import BlockIcon from '@mui/icons-material/Block';

import { Loader } from '@atoms';
import { AccountHeader, MemberDetailsForm } from '@molecules';
import { BlockMemberModal } from '@organisms/Modals';
import { InnerView } from '@templates';
import { Box, Button } from '@mui/material';

import { ManagedAccountsTable } from './ManagedAccountsTable';

export const MemberDetails: FC = () => {
  const { accountId, memberId } = useParams();
  const [disabled, setDisabled] = useState(true);
  const { data, isLoading, refetch } = useQuery<unknown, unknown, MemberType>(
    `member-details/${memberId}`,
    () =>
      Axios.get(`/admin/accounts/business/${accountId}/member/${memberId}`).then((res) => res.data),
  );
  const disabledForm = data?.status !== MemberStatus.Active;

  const { mutate: blockMember } = useMutation(
    () => Axios.patch(`/admin/accounts/business/${accountId}/member/${memberId}`),
    {
      onSuccess: () => {
        successMessage('Member has blocked!');
        refetch();
      },
    },
  );

  const [open, onOpen, onCancel, onSubmit] = useConfirmationModal(false, () => blockMember());

  return (
    <Loader isLoading={isLoading}>
      <Box>
        <AccountHeader
          name={data?.firstName || ''}
          paramsId="workspace-table"
          route={`/${ROUTES.BusinessAccounts}/${accountId}/${ROUTES.Workspace}`}
        >
          <Box display="flex" gap="16px">
            <Button
              variant="outlined"
              color="error"
              onClick={onOpen}
              startIcon={<BlockIcon />}
              disabled={data?.status === MemberStatus.Blocked}
            >
              Block user
            </Button>
            <Button
              variant="contained"
              onClick={onSubmitButtonClick}
              startIcon={<SaveIcon />}
              disabled={disabled}
            >
              Save changes
            </Button>
          </Box>
        </AccountHeader>
        <InnerView autoHeight>
          <Box>
            {data && (
              <MemberDetailsForm
                data={data}
                disabled={disabled}
                setDisabled={setDisabled}
                refetch={refetch}
                disabledForm={disabledForm}
              />
            )}
            {data?.managedAccounts && accountId && memberId && (
              <ManagedAccountsTable id={accountId} memberId={memberId} />
            )}
          </Box>
        </InnerView>
        <BlockMemberModal
          open={open}
          onCancel={onCancel}
          onSubmit={onSubmit}
          fullName={`${data?.firstName} ${data?.lastName}`}
        />
      </Box>
    </Loader>
  );
};

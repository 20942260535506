import { FC } from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import { Box, IconButton, InputLabel } from '@mui/material';

interface FilterCloseWrapPops {
  onClose?: () => void;
  label?: string;
  width?: string;
}

export const FilterCloseWrap: FC<FilterCloseWrapPops> = ({
  children,
  onClose,
  label,
  width = '205px',
}) => (
  <Box width={width}>
    <InputLabel>{label}</InputLabel>
    <Box display="flex" alignItems="center">
      {children}
      {onClose && (
        <IconButton onClick={onClose} color="disabled">
          <CancelIcon fontSize="small" />
        </IconButton>
      )}
    </Box>
  </Box>
);

export enum AdminActivityType {
  // Content Creator
  // Accounts
  BlockContentCreatorAccount = 'blockContentCreatorAccount',
  UpdateContentCreatorInfo = 'updateContentCreatorInfo',
  // Links
  UploadLinksCC = 'uploadLinksCC',
  ExportLinksCC = 'exportLinksCC',
  UpdateLinksSourceTypesCC = 'updateLinksSourceTypesCC',
  UpdateLinksStatusCC = 'updateLinksStatusCC',
  DeleteLinksCC = 'deleteLinksCC',
  // Links Working mode
  UpdateLinksWorkingModeStatusCC = 'updateLinksWorkingModeStatusCC',
  DeleteLinksWorkingModeStatusCC = 'deleteLinksWorkingModeStatusCC',
  // Scan Settings
  UpdateScanSettingsCC = 'updateScanSettingsCC',
  // Documents
  UploadDocumentCC = 'uploadDocumentCC',
  DeleteDocumentCC = 'deleteDocumentCC',
  // Whitelists
  UploadWhiteListsCC = 'uploadWhiteListsCC',
  UpdateWhiteListCC = 'updateWhiteListCC',
  DeleteWhiteListCC = 'deleteWhiteListCC',

  // Business accounts
  // Account detail
  InviteBusinessAccount = 'inviteBusinessAccount',
  UpdateBusinessAccount = 'updateBusinessAccount',
  // Asset
  AddCompanyAsset = 'addCompanyAsset',
  UpdateCompanyAsset = 'updateCompanyAsset',
  RemoveCompanyAsset = 'removeCompanyAsset',
  MoveCompanyAssets = 'movedCompanyAssets',
  // Workspace
  UpdateCompanyWorkspace = 'updateCompanyWorkspace',
  BlockCompanyWorkspace = 'blockCompanyWorkspace',
  // Whitelists
  UploadWhiteListsBA = 'uploadWhiteListsBA',
  UpdateWhiteListBA = 'updateWhiteListBA',
  DeleteWhiteListBA = 'deleteWhiteListBA',
  // Links
  UploadLinksBA = 'uploadLinksBA',
  ExportLinksBA = 'exportLinksBA',
  UpdateLinksSourceTypesBA = 'updateLinkSourceTypesBA',
  UpdateLinksStatusBA = 'updateLinkStatusBA',
  DeleteLinksBA = 'deleteLinksBA',
  // Links working mode
  UpdateLinksWorkingModeStatusBA = 'updateLinksWorkingModeStatusBA',
  DeleteLinksWorkingModeStatusBA = 'deleteLinksWorkingModeStatusBA',
  // Scan Settings
  UpdateScanSettingsBA = 'updateScanSettingsBA',
  // Documents
  UploadDocumentBA = 'uploadDocumentBA',
  DeleteDocumentBA = 'deleteDocumentBA',

  // Users
  InviteUser = 'inviteUser',
  AsignUsers = 'asignUsers',
  BlockUser = 'blockUser',
  UnblockUser = 'unblockUser',
  UpdateUser = 'updateUser',

  // Esign
  ChangeActiveContract = 'changeActiveContract',
  EditContract = 'editContract',

  // Account Settings
  ChangeAccountPassword = 'changeAccountPassword',
  UpdateAccountInformation = 'updateAccountInformation',
}

export type AdminActivityContentCreatorPayload = {
  companyId: string;
  modelId: string;
};

export type AdminActivityBusinessAccountPayload = {
  companyId: string;
  modelId?: string;
};

export type AdminActivityUserPayload = {
  userId: string;
};

export type AdminActivityEsignPayload = {
  contractTemplateId: string;
  contractSubmissionId?: string;
};

export type AdminActivityAccountSettingsPayload = {
  userId: string;
};

export type AdminActivityPayload =
  | AdminActivityContentCreatorPayload
  | AdminActivityBusinessAccountPayload
  | AdminActivityUserPayload
  | AdminActivityEsignPayload
  | AdminActivityAccountSettingsPayload;

export enum AdminActivityUpdateStatusField {
  GeneralScanEnabled = 'general scan',
  PersonalScanEnabled = 'personal scan',
  Keywords = 'keywords',
  Usernames = 'usernames',
  ScanSources = 'scan sources',
  FilterNames = 'filter names',
  FilterRemovals = 'filter removals',
  Pages = 'pages',
  ScanFrequencyDays = 'scan frequency',
  AIFeatures = 'AI features',
}

export enum AdminActivityUpdateCompanyAccountField {
  CompanyName = 'company name',
  FirstName = 'first name',
  LastName = 'last name',
  Slots = 'slots count',
  OverrideFormulaCoef = 'override formula coef',
  TransparencyReportLink = 'transparency report link',
}

export enum AdminActivityUpdateMemberDetailsField {
  FirstName = 'first name',
  LastName = 'last name',
  Role = 'role',
}

export interface AdminActivityResponse {
  id: string;
  text: string;
  adminId: string;
  adminName: string;
  type: string;
  payload: AdminActivityPayload;
  createdAt: Date;
}

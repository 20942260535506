import { FC } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Outlet, useLocation, useParams } from 'react-router-dom';

import { ROUTES } from '@constants';
import { AssetDetailsType, CompanyType, successMessage } from '@services';
import { Axios } from '@helpers';

import { Loader } from '@atoms';
import { AccountHeader, Tabs } from '@molecules';
import { InnerView } from '@templates';
import { Box, Button, Tooltip } from '@mui/material';
import { ConfirmModal } from '@organisms';
import { useConfirmationModal } from '@hooks';

const links = [
  { label: 'Account details', to: ROUTES.AccountDetails },
  { label: 'Links management', to: ROUTES.LinksManagement },
  { label: 'Scan Settings', to: ROUTES.ScanSettings },
  { label: 'Document Uploaded', to: ROUTES.UploadDocument },
  { label: 'Notifications', to: ROUTES.Notification },
];

export const AssetAccountTabs: FC = () => {
  const { type, id, assetId } = useParams();
  const { pathname } = useLocation();
  const isScanTab = pathname.includes(links[2].to);
  const { data, isLoading } = useQuery<unknown, unknown, AssetDetailsType>(
    `asset-account-details/${id}`,
    () => Axios.get(`/admin/accounts/${id}/models/${assetId}`).then((res) => res.data),
  );

  const { mutate: startScan } = useMutation<void, void, void>(
    () => Axios.post(`/admin/companies/${id}/assets/${assetId}/scan`),
    {
      onSuccess: () => {
        successMessage('The scan has been started!\nIt will take up to 5 minutes!');
      },
    },
  );

  const isBusiness = type === CompanyType.Business;
  const routeBackTo = isBusiness
    ? `/${ROUTES.BusinessAccounts}/${id}/${ROUTES.AccountDetails}`
    : `/${ROUTES.ContentCreators}/${id}/${ROUTES.AssetDetails}`;

  const [open, onOpen, onCancel, onSubmit] = useConfirmationModal(false, () => startScan());

  return (
    <Loader isLoading={isLoading}>
      <>
        <AccountHeader
          name={data?.username || ''}
          paramsId="asset-accounts-table"
          route={routeBackTo}
        >
          <Tooltip title="To start fill all the fields on Scan Settings Tab">
            <Box>
              <Button
                variant="contained"
                sx={{ px: '50px' }}
                onClick={onOpen}
                disabled={!data?.canStartScan}
              >
                Start Personal Scan
              </Button>
            </Box>
          </Tooltip>
        </AccountHeader>
        <InnerView autoHeight>
          <Box mt="20px" mb="24px">
            <Tabs
              links={links}
              modified={isScanTab ? data?.scanSettings?.updatedAt : data?.updatedAt}
            />
          </Box>
          <Outlet />
        </InnerView>
        <ConfirmModal
          open={open}
          onCancel={onCancel}
          onSubmit={onSubmit}
          title="Are you sure you want to launch the scan?"
          submitText="Yes, Launch"
        />
      </>
    </Loader>
  );
};
